import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import "./Modal.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";


function MyVerticallyCenteredModal(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  
  function validate() {
    let emailError = "";
    let phoneError = "";
    if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Invalid Email";
    }
    if (!/^[6-9]\d{9}$/.test(contact)) {
      phoneError = "Invalid Phone Number";
    }
    if (emailError || phoneError) {
      toast.error(emailError || phoneError);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/enquiry",
        {
          name,
          email,
          contact,
        }
      );

      toast.success("Thanks for sharing!");
      props.onHide();
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* ... (rest of the modal content) ... */}
    </Modal>
  );
}

export default function MyModal() {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  
  const navigateContact = () => {
    // 👇️ navigate to /
    navigate('/contact');
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "20px 0px",
          padding: "20px",
          background:
            "radial-gradient(circle farthest-corner at 0px 0px, #ccdbff 0%, #f7f5ff 100%)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: "400",
              width: "90%",
            }}
          >
            <div className="courses-features-container">
              <h3>
                <strong>
                  {" "}
                  Are you ready to transform your life through coding?
                </strong>
              </h3>
              <p>
                Geneus Solutions is your passport to a world of coding mastery,
                designed for learners of all levels. Unleash your potential,
                embrace the future, and become a coding genius with us.
              </p>
              <br />
              <h4>
                <strong>Why Choose Our Courses?</strong>
              </h4>{" "}
              <br />

              <div className="feature">
                <h5>Comprehensive Curriculum</h5>
                <p>
                  Our courses are carefully crafted to provide a comprehensive
                  understanding of each technology, ensuring you cover all the
                  essential concepts and advanced techniques.
                </p>
              </div>

              <div className="feature">
                <h5>Hands-on Projects</h5>
                <p>
                  Put your skills into practice with real-world projects that
                  simulate industry scenarios. Gain practical experience and
                  build a portfolio that showcases your abilities.
                </p>
              </div>

              <div className="feature">
                <h5>Expert Instructors</h5>
                <p>
                  Learn from experienced instructors who are passionate about
                  full stack development and dedicated to helping you succeed.
                  Benefit from their insights, industry knowledge, and best
                  practices.
                </p>
              </div>

              <div className="feature">
                <h5>Interactive Learning</h5>
                <p>
                  Engage in interactive learning experiences, including coding
                  exercises, quizzes, and discussions. Collaborate with fellow
                  learners, exchange ideas, and get valuable feedback.
                </p>
              </div>

              <div className="feature">
                <h5>Flexible Learning Options</h5>
                <p>
                  Choose from a variety of learning formats, including
                  self-paced online courses or instructor-led live sessions.
                  Select the option that best fits your schedule and learning
                  preferences.
                </p>
              </div>
            </div>
          </div>
         <Button
            className="angry-animate"
            variant="dark"
            onClick={() => navigateContact()}
            style={{ marginTop: "20px" }}
        > Any Enquiry?</Button>
          
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
