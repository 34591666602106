import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar/Navbar";
import Banner from "./components/Banner/Banner";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Course from "./components/Course/Course";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import CourseDescription from "./components/CourseDescription/CourseDescription";
import Cart from "./components/Cart/Cart";
import { set } from "./redux/slices/cartCount";
import { useSelector, useDispatch } from "react-redux";
import { addUserDetails, userInfo } from "./redux/slices/userDetails";

import Learning from "./components/Learning/Learning";
import CourseContent from "./components/CourseContent/CourseContent";
import AboutGeneus from "./components/Navbar/AboutUs";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import PaymentButton from "./components/Cart/PaymentButton";

axios.defaults.withCredentials = true;

const App = () => {
    const [courses, setCourses] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const dispatch = useDispatch();
    const userDetail = useSelector(userInfo);
    const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetchCourses();
        userDetails();
    }, [userDetail.isLoggedIn]);

    useEffect(() => {
        if (userDetail.isLoggedIn) {
            fetchCartDetails();
        }
    }, [userDetail.isLoggedIn]);

    const fetchCartDetails = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_BACKEND_URL}/cart?user_id=${userDetail.userId}`
            );
            dispatch(set(response.data.cart_items.length));
        } catch (error) {
            console.error("Failed to fetch cart details:", error);
           // alert("Failed to fetch the cart details ");
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/courses`);
            setCourses(response.data);
        } catch (error) {
            console.error("Failed to fetch course data: {} ", error);
         //   alert("Failed to fetch course data ");
        }
    };

    const userDetails = async () => {
        try {
            const response = await axios.post(`${REACT_APP_BACKEND_URL}/userAuth`);
            if (!response.data.authorized) {
                console.log(response.data.authorized);
            } else {
                console.log(response.data.authorized);
                setUserDetailsData(response.data);
            }
        } catch (error) {
            console.log("Failed to fetch user details:", error);
          //  alert("Failed to fetch user details");
        }
    };

    const setUserDetailsData = (data) => {
        const newUserDetails = {
            isLoggedIn: data.authorized,
            username: data.username,
            useremail: data.useremail,
            userId: data.userId,
        };
        dispatch(addUserDetails(newUserDetails));
    };

    const handleSearch = (query) => {
        const filteredCourses = courses.filter((course) =>
            course.title.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredCourses);
    };

    return (
        <Router>
            <ToastContainer theme="colored" position="top-center" />
            <Navbar onSearch={handleSearch} />
            <Routes>
                <Route path="/" element={<Banner />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<AboutGeneus />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                    path="/courses"
                    element={<Course searchResults={searchResults} />}
                />
                <Route path="/blog" element={<Blog />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/courseDes/:id" element={<CourseDescription />} />
                <Route path="/courseCon/:id" element={<CourseContent />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/learning" element={<Learning />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/payment" element={<PaymentButton />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;