import React from "react";
import "./Banner.css";
import "mdbreact/dist/css/mdb.css";
import FSD_Banner from "../../assets/fsd_banner.jpg"
import MyModal from "../Modal/Modal";
import Carousel from "react-bootstrap/Carousel";
import FSD_Mern from "../../assets/fsd_mern.png"
import Banner_Learn from "../../assets/banner_learn.jpg"

const Banner = () => {
    return (
        
        <div>
            <div
                style={{
                    margin: "20px 10rem 20px 5rem",
                    display: "flex",
                    borderRadius: "10px",
                    
                }}
            >
                
                <Carousel
                    fade
                    controls={false}
                    indicators={false}
                    className="d-block w-100"
                    
                >
                    <Carousel.Item interval={2500}>
                        <img
                            src={FSD_Mern}
                            alt=""
                            className="d-block w-100"
                            srcSet=""
                            style={{  borderRadius: "20px", objectFit: "contain" }}
                            height={"600px"}
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <img
                            className="d-block w-100"
                            src={FSD_Banner}
                            w
                            alt=""
                            srcSet=""
                            style={{ borderRadius: "20px", objectFit: "contain" }}
                            height={"600px"}
                            width={"1200px"}
                        />
                       </Carousel.Item>
                       <Carousel.Item interval={2500}>
                        <img
                            className="d-block w-100"
                            src={Banner_Learn}
                            w
                            alt=""
                            srcSet=""
                            style={{ borderRadius: "20px", objectFit: "contain" }}
                            height={"600px"}
                            width={"1200px"}
                        />
                       </Carousel.Item>
                </Carousel>
            </div>
            <MyModal />
        </div>
    );
};

export default Banner;
