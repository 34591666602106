import React from 'react';
import './PrivacyPolicy.css'; // Import the corresponding CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: 22-Jan-2023</p>

<p>
Geneus Solutions is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website or use our services.
</p>
<p>
    <strong>
1. Information We Collect:<br/>
</strong>
1.1 Personal Information:
We may collect personal information, such as your name, email address, phone number, and other information you provide when you interact with our website, subscribe to our newsletter, or use our services.
<br/>
1.2 Usage Data:
We may collect non-personal information about your interactions with our website, including your IP address, browser type, pages visited, and other usage statistics. This information helps us improve our website and services.
</p>
<p>
    <br/>
    <strong>
2. How We Use Your Information:<br/>
</strong>
2.1 Providing Services:
We use your personal information to provide and improve our services, communicate with you, and respond to your inquiries.

2.2 Marketing:
With your consent, we may use your email address to send you newsletters, updates, and promotional materials. You can opt-out of receiving such communications at any time.

2.3 Analytics:
We use analytics tools to analyze website usage and improve our content and services.
</p>
<p>
    <br/>
    <strong>
3. Cookies and Similar Technologies:
</strong>
We may use cookies and similar technologies to enhance your browsing experience and collect information about how you use our website. You can manage your cookie preferences through your browser settings.
</p>
<p>
<strong>
4. Data Security:
</strong>
We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
</p>
<p>
<strong>
5. Third-Party Links:
</strong>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of any third-party sites you visit.
</p>
<p>
<strong>
6. Children's Privacy:
</strong>
Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us to request its deletion.
</p>

<p>
<strong>
7. Changes to this Privacy Policy:
</strong>
We may update this Privacy Policy from time to time. Any changes will be effective when posted on our website. We encourage you to review this Privacy Policy periodically.
</p>
<p>
<strong>
8. Contact Us:
</strong>
If you have any questions or concerns about our Privacy Policy or the use of your personal information, please contact us at support@geneussolutions.in
</p>
    </div>
  );
};

export default PrivacyPolicy;
